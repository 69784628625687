import axios from "axios";
import { Api } from ".";

export const ApiRoutes = {
  authentication: {
    async login(data: any) {
      return Api.request({
        authenticated: false,
        method: "POST",
        path: "/api/authentication/login",
        data,
      });
    },
  },
  topazio: {
    async getWebhook(tradeId: string) {
      return Api.request({
        authenticated: false,
        method: "GET",
        path: `/api/topazio/webhook/${tradeId}`,
      });
    },
    async acceptTrade(id: string, quotationId: string, data: any) {
      return Api.request({
        authenticated: true,
        method: "PUT",
        path: `/api/topazio/trade/${id}`,
        data,
      });
    },
    async createTrade(data: any) {
      return Api.request({
        authenticated: true,
        method: "POST",
        path: "/api/topazio/trade",
        data,
      });
    },
    async getTrades(page: number, queryString: string = "") {
      return Api.request({
        authenticated: true,
        method: "GET",
        path: `/api/topazio/trades?page=${page}${
          queryString ? "&".concat(queryString) : ""
        }`,
      });
    },
    async getTrade(id: string) {
      return Api.request({
        authenticated: true,
        method: "GET",
        path: `/api/topazio/trades?page=1&id=${id}`,
      });
    },
    async deleteTrade(id: string) {
      return Api.request({
        authenticated: true,
        method: "DELETE",
        path: `/api/topazio/trade/${id}`,
      });
    },
    async getContracts(page: number, queryString: string = "") {
      return Api.request({
        authenticated: true,
        method: "GET",
        path: `/api/topazio/contracts?page=${page}${
          queryString ? "&".concat(queryString) : ""
        }`,
      });
    },
    async getContractPreview(createdDate: string) {
      return Api.request({
        authenticated: true,
        method: "GET",
        path: `/api/topazio/contract-preview?createdDate=${createdDate}`,
      });
    },
    async createContract(data: any) {
      return Api.request({
        authenticated: true,
        method: "POST",
        path: "/api/topazio/contract",
        data,
      });
    },
  },
  stillman: {
    async tradingRate(data: any) {
      return Api.request({
        authenticated: true,
        method: "POST",
        path: "/api/partner/stillman/trading/rate",
        data,
      });
    },
  },
  async price() {
    const response = await axios.get<{
      price: number;
    }>(process.env.REACT_APP_API_PRICE_URL);

    const price = parseFloat(
      response.data.price.toString().replace(",", ".")
    ).toFixed(4);

    return Number(price);
  },
  configs: {
    global() {
      return Api.request({
        authenticated: true,
        method: "GET",
        path: "/api/configs/global",
      });
    },

    findByUserId(userId: string) {
      return Api.request({
        authenticated: true,
        method: "GET",
        path: `/api/configs/users/${userId}`,
      });
    },
  },
  contractsPartner: {
    get() {
      return Api.request({
        authenticated: true,
        method: "GET",
        path: "/api/contracts-partner/global",
      });
    },
  },
};
