import { Card, Col, Row, Button, Table, Modal, Form, Input } from 'antd';
import { useState } from 'react';
import { ApiRoutes } from '../../../utils/services/api/routes';
import { StorageKeys, StorageService } from '../../../utils/storage';
import { NotificationTypeEnum, showNotification } from '../../../components/notification';
import { useNavigate } from 'react-router-dom';

export function GuestLoginPage() {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    function authenticateUser({ access_token, access_token_expires, user }: any) {
        StorageService.save(StorageKeys.access_token, access_token);
        StorageService.save(StorageKeys.access_token_expires, access_token_expires);
        StorageService.save(StorageKeys.user_id, user._id);
        StorageService.save(StorageKeys.user, JSON.stringify(user));

        showNotification({
            type: NotificationTypeEnum.success,
            title: 'user_authenticated',
            message: "",
            duration: 10,
        });

        navigate("/home")
    }

    const handleLogin = async () => {
        setLoading(true);
        const body = {
            identification: form.getFieldValue('identification') || null,
            password: form.getFieldValue('password') || null
        }

        await ApiRoutes.authentication
            .login(body)
            .then((response: any) => {
                if (!response) return false;

                authenticateUser({
                    access_token: response.data.data.token.access_token,
                    access_token_expires: response.data.data.token.expires.unix,
                    user: response.data.data.user
                })
            })
            .finally(() => setLoading(false))
    };

    return (<>

        <Modal
            open={true}
            title="Login"
            closable={false}
            centered={true}
            footer={[
                <Button key="login" type="primary" loading={loading} onClick={handleLogin}>
                    Login
                </Button>,
            ]}
        >
            <Form form={form} layout="vertical">
                <Form.Item
                    label="E-mail/ username"
                    name="identification"
                    rules={[{ required: true, message: 'Please enter your identification' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Password"
                    name="password"
                    rules={[{ required: true, message: 'Please enter your password' }]}
                >
                    <Input.Password />
                </Form.Item>
            </Form>
        </Modal>
    </>)
}