import { ChangeEvent, useEffect, useState } from "react";
import { Modal, Form, Input, Checkbox, Button, Radio } from "antd";
import { Helpers } from "../../../utils/helpers";
import { ApiRoutes } from "../../../utils/services/api/routes";
import { NotificationTypeEnum, showNotification } from "../../notification";
import { Emitter } from "../../../utils/emitter";
import { useInterval } from "react-use";

export const CreateOrderComponent = () => {
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [price, setPrice] = useState(0);

  const handleOpenModal = () => {
    setVisible(true);
  };

  const handleCloseModal = () => {
    setVisible(false);
  };

  const handleFormSubmit = async () => {
    const body = {
      partnerId: 49786191000158,
      pairName: `${pair.toLocaleUpperCase()}/USD`,
      operation,
      currency,
      externalValue:
        usdValue > 0 ? usdValue : convertToUSD(externalValue, false),
      internalSettlementDate: nextDate,
      externalSettlementDate: nextDate,
    };

    setLoading(true);

    await ApiRoutes.topazio
      .createTrade(body)
      .then(async (response: any) => {
        const tradeId = response?.data?.resultSet?.id;

        if (!tradeId) return;

        showNotification({
          type: NotificationTypeEnum.success,
          title: "Trade criado!",
          message: "",
          duration: 10,
        });

        Emitter.EventEmitter.emit(Emitter.Event.Action.GetTrades, true);

        await getTrade(tradeId);

        setVisible(false);
      })
      .finally(() => setLoading(false));
  };

  const getTrade = async (tradeId: string) => {
    await ApiRoutes.topazio.getTrade(tradeId).then((response: any) => {
      const data = response?.data?.resultSet?.[0];

      if (!data) return;

      Emitter.EventEmitter.emit(Emitter.Event.Action.OpenModalShowTrade, {
        visible: true,
        data,
      });
    });
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);

    if (!/^\d+$/.test(keyValue)) {
      event.preventDefault();
    }
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const currentValue = event.target.value.replace(/\D/g, "");

    setExternalValue(Number(currentValue || 0));
  };

  const [currency, setCurrency] = useState<"USD" | "EUR" | "GBP">("USD");
  const [operation, setOperation] = useState<"INBOUND" | "OUTBOUND">(
    "OUTBOUND"
  );
  const [rescueDay, setRescueDay] = useState<0 | 1 | 2>(0);
  const [nextDate, setNextDate] = useState<any>(
    Helpers.moment().format("YYYY-MM-DD")
  );
  const [externalValue, setExternalValue] = useState<number>(10000);
  const [usdValue, setUsdValue] = useState<number>(0);
  const [currencyPrice, setCurrencyPrice] = useState<number>(4.8);

  const convertToUSD = (value: number, hasMask: boolean = true) => {
    if (price === 0) return 0;

    if (!hasMask) {
      return Number((value / (price + 0.03)).toFixed(2));
    }

    return Helpers.handleCurrency(Number(value / (price + 0.03)), "USD");
  };

  const [seconds, setSeconds] = useState(5);
  useEffect(() => {
    if (seconds > 0) {
      const interval = setInterval(() => {
        setSeconds((prevSecond) => prevSecond - 1);
      }, 1000);

      return () => clearInterval(interval);
    } else {
      setSeconds(5);
    }
  }, [seconds]);

  const handleNextDay = (value: any) => {
    setRescueDay(value);

    const tempNextDay = Helpers.getNextBusinessDay(Number(value || 0)).format(
      "YYYY-MM-DD"
    );

    setNextDate(tempNextDay);
  };

  const [pair, setPair] = useState<"USDT" | "USDC">("USDT");

  useInterval(async () => {
    if (!visible) return;

    const price = await ApiRoutes.price();

    setPrice(price);
  }, 3000);

  return (
    <>
      <Button type="primary" onClick={handleOpenModal}>
        Criar trade
      </Button>

      <Modal
        open={visible}
        onCancel={handleCloseModal}
        footer={[
          <Button key="cancel" onClick={handleCloseModal}>
            Cancelar
          </Button>,
          <Button
            key="submit"
            type="primary"
            htmlType="submit"
            form="quotationForm"
            loading={loading}
          >
            Iniciar
          </Button>,
        ]}
        maskClosable={false}
        centered={true}
      >
        {/* <h2>Cotação expira em <span className='text-color-important'>{seconds}</span> segundos</h2> */}
        <h2 className="text-primary">Crie o seu trade</h2>

        <Form
          id="quotationForm"
          onFinish={handleFormSubmit}
          initialValues={{
            confirmValues: false,
          }}
        >
          {/* <div>
                    <div className='margin-bottom-md margin-top-lg display-flex'>
                        <div className='input-label margin-right-sm'>Valor unitário:</div>
                        <span>{Helpers.currencyHandle.format(currencyPrice)}</span>
                    </div>
                </div> */}

          <div className="margin-bottom-lg">
            <div className="input-label">
              Dia {Helpers.moment(nextDate).format("DD/MM/YYYY")}{" "}
            </div>
            <Radio.Group
              value={rescueDay}
              onChange={(e) => handleNextDay(e.target.value)}
              buttonStyle="solid"
            >
              <Radio.Button value={0}>D-0</Radio.Button>
              <Radio.Button value={1}>D-1</Radio.Button>
              <Radio.Button value={2}>D-2</Radio.Button>
            </Radio.Group>
          </div>

          <div className="margin-bottom-sm">
            <div className="input-label">Par (Stillman): </div>
            <Radio.Group
              value={pair}
              onChange={(e) => setPair(e.target.value)}
              buttonStyle="solid"
            >
              <Radio.Button value={"USDT"}>USDT</Radio.Button>
              <Radio.Button value={"USDC"}>USDC</Radio.Button>
            </Radio.Group>
          </div>

          <div className="margin-bottom-lg">
            <div className="input-label">Moeda</div>
            <Radio.Group
              value={currency}
              onChange={(e) => setCurrency(e.target.value)}
              buttonStyle="solid"
            >
              <Radio.Button value="USD">USD</Radio.Button>
              {/* <Radio.Button value="EUR">EUR</Radio.Button>
                        <Radio.Button value="GBP">GBP</Radio.Button> */}
            </Radio.Group>
          </div>

          <div className="margin-bottom-lg">
            <div className="input-label">Operação</div>
            <Radio.Group
              value={operation}
              onChange={(e) => setOperation(e.target.value)}
              buttonStyle="solid"
            >
              {/* <Radio.Button value="INBOUND">Inbound</Radio.Button> */}
              <Radio.Button value="OUTBOUND">Outbound</Radio.Button>
            </Radio.Group>
          </div>

          <div className="margin-bottom-lg">
            <div className="input-label">
              Quantidade monetária em BRL:{" "}
              <span className="text-primary">
                {Helpers.handleCurrency(Number(externalValue), "BRL")}
              </span>
            </div>
            <Input
              onChange={handleChange}
              onKeyPress={handleKeyPress}
              value={externalValue}
              inputMode="numeric"
            />
          </div>

          <div
            className="margin-bottom-lg"
            style={{ display: usdValue > 0 ? "none" : "block" }}
          >
            <div className="input-label">Quantidade monetária em USD</div>
            <Input
              value={convertToUSD(externalValue)}
              inputMode="numeric"
              disabled
            />
          </div>

          <div className="margin-bottom-lg">
            <div className="input-label">
              Quantidade monetária em USD:{" "}
              <span className="text-primary">
                {Helpers.handleCurrency(Number(usdValue), "USD")}
              </span>
            </div>
            <Input
              value={usdValue}
              inputMode="numeric"
              onChange={(event) => {
                const currentValue = event.target.value.replace(/\D/g, "");

                setUsdValue(Number(currentValue || 0));
              }}
            />
          </div>

          {/* <div className='total-currency'>
                    <div className='input-label'>Total em BRL</div>
                    <div className='text-primary'>{Helpers.handleCurrency(Number(externalValue * currencyPrice), 'BRL')}</div>
                </div> */}
        </Form>
      </Modal>
    </>
  );
};
