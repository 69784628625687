import { Routes, Route, Navigate, Outlet, useNavigate } from "react-router-dom"
import { ProtectedRoutes, PublicRoutes } from "./utils/route"
import { SiteHomePage } from "./pages/site/home"
import { SiteRoutesComponent } from "./components/site"
import { GuestLoginPage } from "./pages/guest/login"
import { SiteContractPage } from "./pages/site/contract"

export const MainRoutes = () => (
  <Routes>
    {/** Protected Routes */}
    {/** Wrap all Route under ProtectedRoutes element */}
    <Route path='*' element={<Navigate replace to="/not-found" />} />
    {/* <Route path='/not-found' element={<NotFoundPage />} /> */}
    {/** Public Routes */}
    {/** Wrap all Route under PublicRoutes element */}
    <Route path="auth" element={<PublicRoutes />}>
      <Route path="login" element={<GuestLoginPage />} />
    </Route>

    <Route path="/" element={<ProtectedRoutes />}>
      <Route path="/" element={<SiteRoutesComponent />}>
        <Route path="/" element={<Navigate replace to="home" />} />
        <Route path="home" element={<SiteHomePage />} />
        <Route path="contract" element={<SiteContractPage />} />
      </Route>
    </Route>
  </Routes>
)