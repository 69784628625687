import { io, Socket } from 'socket.io-client';

class WebSocketService {
    public socket: Socket | undefined;

    connect() {
        let url = process.env.REACT_APP_WS_URL || "empty";

        url = url.concat('/websocket')

        this.socket = io(url, { transports: ["websocket"] });
    }

    disconnect() {
        this.socket?.disconnect();
    }

    sendMessage(message: string) {
        this.socket?.emit('message', message);
    }

    onMessage(callback: (message: string) => void) {
        this.socket?.on('message', callback);
    }
}

export const webSocketService = new WebSocketService();
