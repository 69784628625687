import { EventEmitter } from 'fbemitter';

export const Emitter = {
    EventEmitter: new EventEmitter(),
    Event: {
        Action: {
            Notification: 'Notification',
            GetTrades: 'GetTrades',
            GetContracts: 'GetContracts',
            OpenModalShowTrade: 'OpenModalShowTrade',
            OpenModalShowContract: 'OpenModalShowContract',
            TopazioCallback: 'TopazioCallback',
            User: 'User',
            UserAuthenticated: 'UserAuthenticated',
        },

    }
}
