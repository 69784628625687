import { useState } from 'react';
import { Drawer, Menu } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import { useMediaQuery } from 'react-responsive';
import { useLogout } from '../../../utils/route';
import { useNavigate } from 'react-router-dom';
import { useCheckPermission } from '../../../utils/storage';

export const SiteHeaderComponent = () => {
    const [visible, setVisible] = useState(false);
    const isDesktop = useMediaQuery({ minWidth: 821 });
    const { Logout } = useLogout();
    const { CheckPermission } = useCheckPermission();

    const showDrawer = () => {
        setVisible(true);
    };

    const closeDrawer = () => {
        setVisible(false);
    };

    const navigate = useNavigate();

    const redirectNav = (path: string) => {
        navigate(path);
    }

    const menuItens = () => (<>
        <Menu.Item key="1" onClick={() => redirectNav('/home')}>Home</Menu.Item>
        {CheckPermission() && <Menu.Item key="2" onClick={() => redirectNav('/contract')}>Boletagem</Menu.Item>}
        <Menu.Item key="3" onClick={Logout}>Sair</Menu.Item>
    </>)

    const renderDesktopNavbar = () => {
        return (
            <div className='header-navbar-desktop'>
                <div className='logo-menu-desktop'>
                    <img src='/photos/white.png' />
                    <span>{(`${process.env.REACT_APP_API_URL}`)}</span>
                </div>
                <Menu mode="vertical" className='bg-secondary'>
                    {menuItens()}
                </Menu>
            </div>
        );
    };

    const renderMobileNavbar = () => {
        return (
            <div className='header-navbar-responsive'>
                <div className="header-navbar-title">
                    <div className='logo-menu-mobile'>
                        <img src='/photos/white.png' />
                    </div>
                </div>
                <div className="header-menu-responsive" onClick={showDrawer}>
                    <MenuOutlined className='header-menu-icon' />
                </div>
                <Drawer
                    title={
                        <div className='logo-menu-mobile'>
                            <img src='/photos/white.png' />
                        </div>
                    }
                    placement="right"
                    onClose={closeDrawer}
                    closeIcon={false}
                    open={visible}
                    width={300}
                    headerStyle={{ background: '#141414' }}
                    bodyStyle={{ padding: 0, background: '#141414' }}
                    contentWrapperStyle={{ background: '#141414' }}
                >
                    <Menu mode="inline">
                        {menuItens()}
                    </Menu>
                </Drawer>
            </div>
        );
    };

    return (
        <div className='header-navbar'>
            {isDesktop ? renderDesktopNavbar() : renderMobileNavbar()}
        </div>
    );
};
