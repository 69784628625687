import React, { useEffect, useState } from "react";
import { Button, Col, Modal, Radio, Row, Space, Tag, Typography } from "antd";
import { ApiRoutes } from "../../../utils/services/api/routes";
import { NotificationTypeEnum, showNotification } from "../../notification";
import { Emitter } from "../../../utils/emitter";
import { Helpers } from "../../../utils/helpers";

export const ShowTradeComponent = () => {
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [loadingAccept, setLoadingAccept] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [data, setData] = useState<any>({});
  const [responseProvider, setResponseProvider] = useState<any>({});
  const [updatedAt, setUpdatedAt] = useState<any>(
    Helpers.moment().format(Helpers.DateFormatWithSeconds)
  );
  const [webhook, setWebhook] = useState<any>({});
  let intervalId: any;
  const [attemptCount, setAttemptCount] = useState(0);
  const [fee, setFee] = useState(0);
  const [spread, setSpread] = useState(0);

  useEffect(() => {
    ApiRoutes.configs.global().then((response: any) => {
      setFee(response.data?.fee);
      setSpread(response.data?.spread);
    });
  }, []);

  // function syncWebhook(tradeId: string) {
  //     let attemptCount = 0;
  //     let intervalId: NodeJS.Timeout | null = null;

  //     const fetchData = async () => {
  //         await ApiRoutes.topazio
  //             .getWebhook(tradeId)
  //             .then((response: any) => {

  //                 if (!response?.data?.data) {
  //                     attemptCount++;

  //                     if (attemptCount >= 5) {
  //                         clearInterval(intervalId!);
  //                     }

  //                     return;
  //                 }

  //                 const value = response?.data?.data?.data;

  //                 setWebhook(value);
  //                 updateData(value);
  //             })
  //             .finally(() => setLoadingAccept(false));
  //     };

  //     fetchData();

  //     if (!intervalId) {
  //         intervalId = setInterval(fetchData, 1000);
  //     }

  //     return () => {
  //         if (intervalId) {
  //             clearInterval(intervalId);
  //             intervalId = null;
  //         }
  //     };
  // }

  const onCancel = async () => {
    setModalVisible(false);
    clearInterval(intervalId);
  };

  useEffect(() => {
    Emitter.EventEmitter.addListener(
      Emitter.Event.Action.OpenModalShowTrade,
      (parameters: any) => {
        setModalVisible(false);
        setModalVisible(parameters.visible);
        setData(parameters.data);
        // setBuyRate(parameters.data.providerAssetValue)
        setAttemptCount(0);
        // if (parameters.data.status == 1) {
        //     stillmanTradingRate(parameters.data.externalValue)
        // }
      }
    );
  }, []);

  // const [buyRate, setBuyRate] = useState<any>(1);

  // const stillmanTradingRate = async (externalValue: number) => {
  //     if (!externalValue) return;

  //     await ApiRoutes.stillman
  //         .tradingRate(
  //             {
  //                 "pairName": `${pair.toLocaleUpperCase()}/USD`,
  //                 "requestAmount": externalValue,
  //                 "requestInBaseAsset": true
  //             }
  //         )
  //         .then((response: any) => {
  //             if (!response?.data?.buyRate) return;

  //             setResponseProvider(response.data);

  //             setBuyRate(response.data.buyRate || 1)
  //         })
  // }

  // const [pair, setPair] = useState<'USDT' | 'USDC'>('USDT');

  // useEffect(() => {
  //     if (data.status === 1) {
  //         const interval = setInterval(async () => {
  //             stillmanTradingRate(Number(data.externalValue * buyRate));
  //         }, 5000);

  //         return () => {
  //             clearInterval(interval);
  //         };
  //     }
  // }, [data, pair]);

  useEffect(() => {
    Emitter.EventEmitter.addListener(
      Emitter.Event.Action.TopazioCallback,
      (parameters: any) => {
        if (parameters?.trade_id === data.id) {
          updateData(parameters);
        }
      }
    );
  }, [data]);

  function updateData(parameters: any) {
    setData((prevState: any) => ({
      ...prevState,
      quotationId: parameters.quotation_id || prevState.quotationId,
      fxRate: parameters.fx_rate || prevState.fxRate,
      internalValue: parameters.internal_value || prevState.internalValue,
      externalValue: parameters.external_value || prevState.externalValue,
      currency: parameters.currency || prevState.currency,
    }));
    setUpdatedAt(Helpers.moment().format(Helpers.DateFormatWithSeconds));
  }

  function renderValue({ isBRL, isUSD, isDate, value }: any) {
    if (isBRL) {
      return Helpers.handleCurrency(Number(value), "BRL");
    }
    if (isUSD) {
      return Helpers.handleCurrency(Number(value), "USD");
    }
    if (isDate) {
      return Helpers.moment(value).format(Helpers.DateFormat);
    } else {
      return value;
    }
  }

  const renderDataItems = () => {
    return Object.entries(data).map(([column, value]: any, index: number) => (
      <div key={`${column}_${index}`} className="trade-view-item">
        <Typography.Text strong className="margin-right-md">
          {column}:
        </Typography.Text>
        <Typography.Text>
          {renderValue({
            isBRL: ["internalValue"].includes(column),
            isUSD: ["externalValue"].includes(column),
            isDate: column?.toLowerCase()?.includes("date"),
            value,
          })}
        </Typography.Text>
      </div>
    ));
  };

  const acceptTrade = async () => {
    setLoadingAccept(true);

    await ApiRoutes.topazio
      .acceptTrade(data.id, data.quotationId, {
        quotationId: data.quotationId,
        provider_asset_name: "stillman",
        provider_asset_price: 1,
      })
      .then((response: any) => {
        if (!response) return;

        showNotification({
          type: NotificationTypeEnum.success,
          title: "Trade aceito!",
          message: "",
          duration: 5,
        });

        Emitter.EventEmitter.emit(Emitter.Event.Action.GetTrades, true);

        onCancel();
      })
      .finally(() => setLoadingAccept(false));
  };

  const cancelTrade = async () => {
    setLoadingDelete(true);

    await ApiRoutes.topazio
      .deleteTrade(data.id)
      .then((response: any) => {
        if (!response) return;

        showNotification({
          type: NotificationTypeEnum.success,
          title: "Trade cancelado!",
          message: "",
          duration: 5,
        });

        Emitter.EventEmitter.emit(Emitter.Event.Action.GetTrades, true);

        onCancel();
      })
      .finally(() => setLoadingDelete(false));
  };

  const quotation = Helpers.addFee(Number(data.fxRate), fee).toFixed(4);

  return (
    <Modal
      open={modalVisible}
      title={
        <Row align={"middle"} justify={"center"} gutter={16}>
          <Col>
            <h2>Trade</h2>
          </Col>
          <Col>
            <>
              {Number(data.status) === 0 && (
                <Tag color="#ff5454">Encerrado</Tag>
              )}
              {Number(data.status) === 1 && (
                <Tag color="#F9AA4B">Em Aberto</Tag>
              )}
              {Number(data.status) === 2 && <Tag color="#BF40BF">Aceito</Tag>}
              {Number(data.status) === 4 && (
                <Tag color="#108ee9">Aprovado pela tesouraria</Tag>
              )}
              {Number(data.status) === 5 && <Tag color="#56c1aa">Boletado</Tag>}
              {Number(data.status) === 6 && (
                <Tag color="#17D4B3">Efetivado</Tag>
              )}
            </>
          </Col>
        </Row>
      }
      onCancel={onCancel}
      destroyOnClose={true}
      maskClosable={false}
      key={"modal_trade"}
      footer={[
        <>
          {data.status == 1 && (
            <>
              <Button
                type="primary"
                danger={true}
                loading={loadingDelete}
                onClick={cancelTrade}
                key={"cancel_trade"}
              >
                Cancelar ordem
              </Button>
              <Button
                type="primary"
                danger={false}
                loading={loadingAccept}
                onClick={acceptTrade}
                key={"agree_trade"}
              >
                Aceitar
              </Button>
            </>
          )}
        </>,
      ]}
    >
      {data?.currency && (
        <Row justify={"center"}>
          <Col lg={18}>
            <div className="trade-order">
              <div className="margin-bottom-md">
                <div className="title-large">
                  Cotação em {data.currency}
                  <div className="text-primary">$ {quotation}</div>
                </div>
                {data.status == 1 && (
                  <div>
                    Última atualização:{" "}
                    <span className="text-color-primary">{updatedAt}</span>
                  </div>
                )}
              </div>
              {/* {
                            data.status === 1 && (
                                <div className='margin-bottom-sm'>
                                    <div className='input-label'>Par: </div>
                                    <Radio.Group value={pair} onChange={(e) => setPair(e.target.value)} buttonStyle="solid">
                                        <Radio.Button value={'USDT'}>USDT</Radio.Button>
                                        <Radio.Button value={'USDC'}>USDC</Radio.Button>
                                    </Radio.Group>
                                </div>
                            )
                        } */}
              <div className="trade-order-info">
                <div className="trade-order-info-title">Id:</div>
                <div className="trade-order-info-text">{data.id}</div>
              </div>
              <div className="trade-order-info">
                <div className="trade-order-info-title">
                  Quantidade em {data.currency}:
                </div>
                <div className="trade-order-info-text">
                  {Helpers.handleCurrency(
                    Number(data.externalValue),
                    data.currency
                  )}
                </div>
              </div>
              <div className="trade-order-info">
                <div className="trade-order-info-title">Quantidade em BRL:</div>
                <div className="trade-order-info-text">
                  {Helpers.handleCurrency(
                    Helpers.addFeeOld(Number(data.externalValue), +quotation),
                    "BRL"
                  )}
                </div>
              </div>
              <div className="trade-order-info">
                <div className="trade-order-info-title">Criado em:</div>
                <div className="trade-order-info-text">
                  {Helpers.moment(data.createdDate).format(Helpers.DateFormat)}
                </div>
              </div>
              <div className="trade-order-info">
                <div className="trade-order-info-title">
                  Data de processamento interno:
                </div>
                <div className="trade-order-info-text">
                  {Helpers.moment(data.internalSettlementDate).format(
                    Helpers.OnlyDateFormat
                  )}
                </div>
              </div>
              <div className="trade-order-info">
                <div className="trade-order-info-title">
                  Data de processamento externo:
                </div>
                <div className="trade-order-info-text">
                  {Helpers.moment(data.externalSettlementDate).format(
                    Helpers.OnlyDateFormat
                  )}
                </div>
              </div>
            </div>
          </Col>
        </Row>
      )}
    </Modal>
  );
};
