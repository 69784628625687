import { useLogout } from "../route";

export enum StorageKeys {
    language = 'language',
    user = 'user',
    user_id = 'user_id',
    message_title = 'message_title',
    message_content = 'message_content',
    message_type = 'message_type',
    access_token = 'access_token',
    access_token_expires = 'access_token_expires',
}

export const StorageService = {
    get: (key: StorageKeys) => {
        return (typeof window !== 'undefined') ? window.localStorage.getItem(key) : false;
    },
    getObject: (key: StorageKeys) => {
        return JSON.parse(StorageService.get(key) || "{}");
    },
    save: (key: StorageKeys, data: any) => {
        return (typeof window !== 'undefined') ? window.localStorage.setItem(key, data) : false;
    },
    delete: (key: StorageKeys) => {
        return (typeof window !== 'undefined') ? window.localStorage.removeItem(key) : false;
    }
}

export const useUserAuthenticated = () => {
    const { Logout } = useLogout();

    const userAuthenticated = () => {
        const getData = StorageService.get(StorageKeys.user);

        try {
            if (!getData) {
                Logout();
                return;
            }

            return JSON.parse(getData);
        } catch (error) {
            Logout();
        }
    };

    return { userAuthenticated };
};

export const useCheckPermission = () => {
    const { userAuthenticated } = useUserAuthenticated();

    const CheckPermission = (permissionsInformed: string[] = []) => {
        const { permissions } = userAuthenticated()

        return permissions.some((permission: string) => ['admin', ...permissionsInformed].includes(permission));
    }

    return { CheckPermission };
}