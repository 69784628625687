import axios, { Method } from "axios";
import { Emitter } from "../../emitter";
import { StorageKeys, StorageService } from "../../storage";
import { NotificationTypeEnum } from "../../../components/notification";

export interface IApiRequest {
  method: Method;
  path: string;
  data?: any;
  headers?: any;
  authenticated: boolean;
}

export const Api = {
  handleError(error: any) {
    if (error?.response?.data?.message) return error?.response?.data?.message;
    if (error?.response?.data?.error) return error?.response?.data?.error;
  },
  async request(properties: IApiRequest) {
    const url = process.env.REACT_APP_API_URL?.concat(properties.path);
    const access_token = StorageService.get(StorageKeys.access_token);

    if (properties.authenticated && access_token) {
      properties.headers = {
        ...properties.headers,
        Authorization: "Bearer ".concat(access_token),
      };
    }

    return await axios({
      method: properties.method,
      url,
      data: properties.data,
      headers: properties.headers,
    }).catch((error) => this.handleCatch(error));
  },
  handleCatch(error: any) {
    let title = "error_occurred";

    try {
      if (error?.response?.data?.error?.message) {
        title = error?.response?.data?.error?.message;
      } else if (error?.response?.data?.message) {
        title = Array.isArray(error?.response?.data?.message)
          ? (error?.response?.data?.message[0]).toLowerCase()
          : (error?.response?.data?.message).toLowerCase();
      } else if (error?.response?.data?.error) {
        title = (error?.response?.data?.error).toLowerCase();
      }
    } catch (errorData: any) {}

    Emitter.EventEmitter.emit(Emitter.Event.Action.Notification, {
      type: NotificationTypeEnum.error,
      title: error?.response?.data?.urgent
        ? error?.response?.data?.urgent
        : `${title.toLowerCase()}`,
      message: "",
      duration: 5,
      i18n: true,
    });
  },
};
