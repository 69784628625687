import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import { SiteHeaderComponent } from './header';
import { useEffect } from 'react';
import { webSocketService } from '../../utils/services/websocket';
import { Emitter } from '../../utils/emitter';
import { NotificationTypeEnum } from '../notification';
import { StorageKeys, StorageService } from '../../utils/storage';

export function SiteRoutesComponent() {
    const translate = useTranslation();

    useEffect(() => {
        webSocketService.connect();
        const { socket } = webSocketService;
        if (socket) {
            socket.on('connect', () => {
                const access_token = StorageService.get(StorageKeys.access_token);
                socket.send(JSON.stringify({ access_token }));
            });

            socket.on('topazio_callback', (data) => {
                Emitter.EventEmitter.emit(Emitter.Event.Action.Notification, {
                    type: NotificationTypeEnum.success,
                    title: "Cotação Atualizada"
                })
                Emitter.EventEmitter.emit(Emitter.Event.Action.TopazioCallback, data)
            });
        }
        return () => {
            webSocketService.disconnect();
        };
    }, [])

    return (
        <>
            <SiteHeaderComponent />
            <div>
                <Outlet context={[translate]} />
            </div>
        </>
    );
}