import { Modal, Row, Col, Tag, Typography } from "antd";
import { useEffect, useState } from "react";
import { Helpers } from "../../../utils/helpers";
import { Emitter } from "../../../utils/emitter";
const { Text } = Typography;

const ContractModal = ({ visible, onClose, data }: any) => {
  return (
    <Modal
      title="Detalhes da Transação"
      open={visible}
      centered={true}
      onCancel={onClose}
      footer={null}
      width={1000}
      className="margin-top-lg margin-bottom-lg"
    >
      {data?.id && (
        <>
          <Row>
            <Col lg={12}>
              <Row className="margin-bottom-md">
                <Col xs={24} md={8}>
                  <Text strong>Corban:</Text>
                </Col>
                <Col xs={24} md={12}>
                  <Text>{data.corban}</Text>
                </Col>
              </Row>
              <Row className="margin-bottom-md">
                <Col xs={24} md={8}>
                  <Text strong>ID do Corban:</Text>
                </Col>
                <Col xs={24} md={12}>
                  <Text>{data.corbanId}</Text>
                </Col>
              </Row>
              <Row className="margin-bottom-md">
                <Col xs={24} md={8}>
                  <Text strong>Trade IDs:</Text>
                </Col>
                <Col xs={24} md={24}>
                  {data.tradeIds.map((tradeId: any) => (
                    <Tag color="#56c1aa" key={tradeId}>
                      {tradeId}
                    </Tag>
                  ))}
                </Col>
              </Row>
              <Row className="margin-bottom-md">
                <Col xs={24} md={8}>
                  <Text strong>ID:</Text>
                </Col>
                <Col xs={24} md={12}>
                  <Text>{data.id}</Text>
                </Col>
              </Row>
              <Row className="margin-bottom-md">
                <Col xs={24} md={8}>
                  <Text strong>Data de Liquidação Externa:</Text>
                </Col>
                <Col xs={24} md={12}>
                  <Text>
                    {Helpers.moment(data.externalSettlementDate).format(
                      Helpers.DateFormat
                    )}
                  </Text>
                </Col>
              </Row>
              <Row className="margin-bottom-md">
                <Col xs={24} md={8}>
                  <Text strong>Data de Liquidação Interna:</Text>
                </Col>
                <Col xs={24} md={12}>
                  <Text>
                    {Helpers.moment(data.internalSettlementDate).format(
                      Helpers.DateFormat
                    )}
                  </Text>
                </Col>
              </Row>
              <Row className="margin-bottom-md">
                <Col xs={24} md={8}>
                  <Text strong>Data de Criação:</Text>
                </Col>
                <Col xs={24} md={12}>
                  <Text>
                    {Helpers.moment(data.createdDate).format(
                      Helpers.DateFormat
                    )}
                  </Text>
                </Col>
              </Row>
              <Row className="margin-bottom-md">
                <Col xs={24} md={8}>
                  <Text strong>País:</Text>
                </Col>
                <Col xs={24} md={12}>
                  <Text>{data.country}</Text>
                </Col>
              </Row>
              <Row className="margin-bottom-md">
                <Col xs={24} md={8}>
                  <Text strong>Status:</Text>
                </Col>
                <Col xs={24} md={12}>
                  <Text>{Helpers.render.contractStatus(data)}</Text>
                </Col>
              </Row>
              <Row className="margin-bottom-md">
                <Col xs={24} md={8}>
                  <Text strong>Taxa de Câmbio:</Text>
                </Col>
                <Col xs={24} md={12}>
                  <Text>{data.fxRate}</Text>
                </Col>
              </Row>
              <Row className="margin-bottom-md">
                <Col xs={24} md={8}>
                  <Text strong>Valor Externo:</Text>
                </Col>
                <Col xs={24} md={12}>
                  <Text>{data.externalValue}</Text>
                </Col>
              </Row>
              <Row className="margin-bottom-md">
                <Col xs={24} md={8}>
                  <Text strong>Valor Interno:</Text>
                </Col>
                <Col xs={24} md={12}>
                  <Text>{data.internalValue}</Text>
                </Col>
              </Row>
            </Col>
            <Col lg={12}>
              <Row className="margin-bottom-md">
                <Col xs={24} md={8}>
                  <Text strong>Valor IOF:</Text>
                </Col>
                <Col xs={24} md={12}>
                  <Text>{data.iofValue}</Text>
                </Col>
              </Row>
              <Row className="margin-bottom-md">
                <Col xs={24} md={8}>
                  <Text strong>Natureza:</Text>
                </Col>
                <Col xs={24} md={12}>
                  <Text>{data.nature}</Text>
                </Col>
              </Row>
              <Row className="margin-bottom-md">
                <Col xs={24} md={8}>
                  <Text strong>Nome Externo:</Text>
                </Col>
                <Col xs={24} md={12}>
                  <Text>{data.externalName}</Text>
                </Col>
              </Row>
              <Row className="margin-bottom-md">
                <Col xs={24} md={8}>
                  <Text strong>Endereço Externo:</Text>
                </Col>
                <Col xs={24} md={12}>
                  <Text>{data.externalAddress}</Text>
                </Col>
              </Row>
              <Row className="margin-bottom-md">
                <Col xs={24} md={8}>
                  <Text strong>IBAN Externo:</Text>
                </Col>
                <Col xs={24} md={12}>
                  <Text>{data.externalIban}</Text>
                </Col>
              </Row>
              <Row className="margin-bottom-md">
                <Col xs={24} md={8}>
                  <Text strong>SWIFT do Banco Recebedor:</Text>
                </Col>
                <Col xs={24} md={12}>
                  <Text>{data.receiverBankSwift}</Text>
                </Col>
              </Row>
              <Row className="margin-bottom-md">
                <Col xs={24} md={8}>
                  <Text strong>Cidade do Banco Recebedor:</Text>
                </Col>
                <Col xs={24} md={12}>
                  <Text>{data.receiverBankCity}</Text>
                </Col>
              </Row>
              <Row className="margin-bottom-md">
                <Col xs={24} md={8}>
                  <Text strong>ABA do Banco Recebedor:</Text>
                </Col>
                <Col xs={24} md={12}>
                  <Text>{data.receiverBankAba}</Text>
                </Col>
              </Row>
              <Row className="margin-bottom-md">
                <Col xs={24} md={8}>
                  <Text strong>SWIFT do Banco Intermediário:</Text>
                </Col>
                <Col xs={24} md={12}>
                  <Text>{data.intermBankSwift}</Text>
                </Col>
              </Row>
              <Row className="margin-bottom-md">
                <Col xs={24} md={8}>
                  <Text strong>Cidade do Banco Intermediário:</Text>
                </Col>
                <Col xs={24} md={12}>
                  <Text>{data.intermBankCity}</Text>
                </Col>
              </Row>
              <Row className="margin-bottom-md">
                <Col xs={24} md={8}>
                  <Text strong>ABA do Banco Intermediário:</Text>
                </Col>
                <Col xs={24} md={12}>
                  <Text>{data.intermBankAba}</Text>
                </Col>
              </Row>
              <Row className="margin-bottom-md">
                <Col xs={24} md={8}>
                  <Text strong>Referência do Cliente:</Text>
                </Col>
                <Col xs={24} md={12}>
                  <Text>{data.clientReference}</Text>
                </Col>
              </Row>
            </Col>
          </Row>
          {data?.obs && (
            <Row className="margin-bottom-md">
              <Col xs={24} md={4}>
                <Text strong>Observações:</Text>
              </Col>
              <Col xs={24} md={20}>
                <Text>{data.obs}</Text>
              </Col>
            </Row>
          )}
        </>
      )}
    </Modal>
  );
};

export const ShowContractComponent = () => {
  const [modalVisible, setModalVisible] = useState(false);

  const [data, setData] = useState<any>({});

  useEffect(() => {
    Emitter.EventEmitter.addListener(
      Emitter.Event.Action.OpenModalShowContract,
      (parameters: any) => {
        setModalVisible(false);
        console.log({ parameters });
        setModalVisible(parameters.visible);
        setData(parameters.data);
      }
    );
  }, []);

  const openModal = () => {
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  return (
    <div>
      {/* <button onClick={openModal}>Abrir Modal</button> */}
      <ContractModal visible={modalVisible} onClose={closeModal} data={data} />
    </div>
  );
};
